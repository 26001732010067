.logo img {
  max-width: 400px;
}
.home-header {
  font-size: 120px;
  font-weight: 900;
  line-height: 100px;
}
.home-header span {
  color: var(--brand-color);
}
.logo {
  height: 100vh;
}
.home-header-two {
  line-height: 10px;
  font-size: 60px;
}
.input input {
  border-color: var(--brand-color);
}
